var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"\n        align-self-center\n        text-white\n        rounded-circle\n        align-self-center\n        bg-dark-green\n        font-rem-1-0\n        mx-3\n        d-flex\n        justify-content-center\n        align-items-center\n      ",style:({
        'min-width': '30px',
        width: '30px',
        'min-height': '30px',
        height: '30px'
      })},[_c('span',{},[_vm._v(" 3")])]),_c('div',[_vm._v("Actualiza las redes cercanas.")])]),_c('img',{staticClass:"my-2 align-self-center",attrs:{"src":"/3.jpg","height":"77px","width":"267px","alt":"mid-box"}}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"\n        align-self-center\n        text-white\n        rounded-circle\n        align-self-center\n        bg-dark-green\n        font-rem-1-0\n        mx-3\n        d-flex\n        justify-content-center\n        align-items-center\n      ",style:({
        'min-width': '30px',
        width: '30px',
        'min-height': '30px',
        height: '30px'
      })},[_c('span',{},[_vm._v(" 4")])]),_c('div',{staticClass:"brown-green-bold text-left font-rem-0-9 pt-5"},[_vm._v(" Buscar la red wseeds que genera la midbox e introduce de contraseña \"wseeds\" para ingresar. ")])]),_c('img',{staticClass:"my-2 align-self-center",attrs:{"src":"/4.jpg","height":"77px","width":"267px","alt":"mid-box"}}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"\n        align-self-center\n        text-white\n        rounded-circle\n        align-self-center\n        bg-dark-green\n        font-rem-1-0\n        mx-3\n        d-flex\n        justify-content-center\n        align-items-center\n      ",style:({
        'min-width': '30px',
        width: '30px',
        'min-height': '30px',
        height: '30px'
      })},[_c('span',{},[_vm._v(" 5")])]),_c('div',{staticClass:"brown-green-bold text-left font-rem-0-9 pt-5"},[_vm._v(" Asegúrate de estar conectado a la red wseeds. ")])]),_c('img',{staticClass:"my-2 align-self-center",attrs:{"src":"/5.jpg","height":"77px","width":"267px","alt":"mid-box"}})])}
var staticRenderFns = []

export { render, staticRenderFns }